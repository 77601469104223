import React, { Component } from 'react';
import { Link } from "gatsby";

export default class ErrorComponent extends Component {
    render() {
        return (
            <div className="centerMessage">
                <h3><span className="text-danger">ERRROR 404!</span><small className="text-muted">&nbsp;This page does not exist...</small></h3>
                <Link to="/" exact>
                    <div className="row h-100 justify-content-center align-items-center pt-5">
                        <button type="button" className="btn btn-outline-dark">Back to Home Page</button>
                    </div>
                </Link>
            </div>
        )
    }
}